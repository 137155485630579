import {
  ICourse,
  UpdateCourseActionPayload,
} from "../domain/e-learning/e-learning.types";
import { FileDragAndDropProvider } from "../components/DragAndDrop/DragAndDropContext";
import createDefaultVideoSrtInputValue from "../utils/createDefaultVideoSrtInputValue";
import createDefaultVideoInputValue from "../utils/createDefaultVideoInputValue";
import { selectCourseById } from "../domain/e-learning/e-learning.selectors";
import ThemeHeaderCard from "../components/ThemeHeaderCard/ThemeHeaderCard";
import { updateCourse } from "../domain/e-learning/e-learning.actions";
import StepNav, { Step } from "../components/StepBar/StepNav";
import CourseForm from "../components/CourseForm/CourseForm";
import FileUpload from "../components/FileUpload/FileUpload";
import { generatePath, useParams } from "react-router-dom";
import LeftSidebar from "../components/Page/LeftSidebar";
import { useDispatch, useSelector } from "react-redux";
import createFileList from "../utils/createFileList";
import Content from "../components/Page/Content";
import Topbar from "../components/Page/Topbar";
import useNavigate from "../utils/useNavigate";
import { useTranslation } from "react-i18next";
import Page from "../components/Page/Page";
import mapWithId from "../utils/mapWithId";
import { AppState } from "../redux/types";
import { FC, useMemo } from "react";
import "./EditCourse.scss";

const EditCourse: FC = () => {
  const { t } = useTranslation();
  const params = useParams<{ themeId: string; courseId: string }>();
  const dispatch = useDispatch();
  const navigate = useNavigate(`/e-learning?theme="${params.themeId}"`);
  const onSubmit = (data: UpdateCourseActionPayload) => {
    dispatch(updateCourse(params.courseId, data));
    navigate();
  };

  const defaultValues = useSelector<AppState, ICourse | undefined>(
    selectCourseById(params.themeId, params.courseId),
  );

  const steps = useMemo<Step[]>(
    () =>
      mapWithId([
        {
          label: "e_learning_edit",
          to: generatePath("/e-learning/:id/edit", { id: params.themeId }),
        },
        {
          label: "e_learning_edit_course",
          to: generatePath("/e-learning/:id/courses/:courseId", {
            id: params.themeId,
            courseId: params.courseId,
          }),
        },
        {
          label: "e_learning_add_exercises",
          to: generatePath("/e-learning/:id/exercises/add", {
            id: params.themeId,
          }),
        },
      ]),
    [params],
  );

  return (
    <Page disabledGutters>
      <FileDragAndDropProvider>
        <Topbar className="edit-course-topbar">
          <StepNav steps={steps} />
        </Topbar>
        <LeftSidebar className="edit-course-left-sidebar">
          <ThemeHeaderCard id={params.themeId} />
          <FileUpload
            label={t("import_file")}
            accept={"video/mp4,video/webm"}
            multiple
          />
        </LeftSidebar>
        <Content>
          <CourseForm
            title="e_learning_edit_course"
            onSubmit={onSubmit}
            defaultValues={{
              ...defaultValues,
              picto: defaultValues?.pictoName
                ? createFileList(defaultValues.pictoName)
                : "",
              video: createDefaultVideoInputValue(defaultValues?.videoId),
              srt: createDefaultVideoSrtInputValue(defaultValues?.videoId),
            }}
          />
        </Content>
      </FileDragAndDropProvider>
    </Page>
  );
};

export default EditCourse;

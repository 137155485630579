import {
  createContext,
  DragEvent,
  DragEventHandler,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from "react";

interface FileDragAndDropContextProps {
  children: ReactNode;
}

type FileDropHandler = (files: FileList) => void;

type FileDragAndDropContextType = {
  draggedFile: File | null;
  onFileDragStart: (file: File) => DragEventHandler<HTMLInputElement>;
  onFileDragOver: DragEventHandler<HTMLInputElement>;
  onFileDrop: (
    handleFileDrop: FileDropHandler,
  ) => DragEventHandler<HTMLInputElement>;
};

const FileDragAndDropContext = createContext<FileDragAndDropContextType>({
  draggedFile: null,
  onFileDragStart: () => () => {},
  onFileDragOver: () => {},
  onFileDrop: () => () => {},
});

export const useFileDragAndDrop = () => {
  const context = useContext(FileDragAndDropContext);

  return context;
};

export const FileDragAndDropProvider: FC<FileDragAndDropContextProps> = ({
  children,
}) => {
  const [draggedFile, setDraggedFile] = useState<File | null>(null);

  const onFileDragStart = useCallback(
    (file) => () => {
      setDraggedFile(file);
    },
    [],
  );

  const onFileDragOver = useCallback((event: DragEvent<HTMLInputElement>) => {
    event.preventDefault();
  }, []);

  const onFileDrop = useCallback(
    (handleFileDrop: FileDropHandler) =>
      (event: DragEvent<HTMLInputElement>) => {
        event.preventDefault();

        if (draggedFile) {
          const file = new File([" "], draggedFile.name, {
            type: draggedFile.type,
            lastModified: Date.now(),
          });

          Object.defineProperty(file, "size", { value: draggedFile.size });

          const dataTransfer = new DataTransfer();
          dataTransfer.items.add(file);

          handleFileDrop(dataTransfer.files);
        }
      },
    [draggedFile],
  );

  return (
    <FileDragAndDropContext.Provider
      value={{ onFileDragStart, onFileDragOver, onFileDrop, draggedFile }}
    >
      {children}
    </FileDragAndDropContext.Provider>
  );
};

import {
  CreateExerciseActionPayload,
  IQuestion,
  UpdateExerciseActionPayload,
} from "../../domain/e-learning/e-learning.types";
import createDefaultVideoInputValue from "../../utils/createDefaultVideoInputValue";
import { DefaultValues, useFieldArray, useFormContext } from "react-hook-form";
import QuestionForm from "../QuestionForm/QuestionForm";
import { useTranslation } from "react-i18next";
import { IoAddCircle } from "react-icons/io5";
import { useParams } from "react-router-dom";
import Button from "../Button/Button";
import classNames from "classnames";
import Paper from "../Paper/Paper";
import { v4 as uuid } from "uuid";
import "./ExerciseForm.scss";
import { FC } from "react";

export type ExerciseFormValues =
  | CreateExerciseActionPayload
  | UpdateExerciseActionPayload;

interface ExerciseFormProps {
  themeId?: string;
  onSubmit: (data: ExerciseFormValues) => void;
  defaultValues?: DefaultValues<ExerciseFormValues>;
}

const ExerciseForm: FC<ExerciseFormProps> = ({
  defaultValues = {},
  onSubmit,
}) => {
  const params = useParams<{ themeId: string; exerciseId: string }>();

  const { t } = useTranslation();

  const { handleSubmit, control, register, errors } = useFormContext();

  const { fields, append, remove } = useFieldArray<IQuestion>({
    name: "questions",
    control: control,
  });

  const requiredErrorMessage = t("field_required");

  const onAddQuestionBtnClick = () => {
    append({
      id: uuid(),
      exerciseId: params.exerciseId,
    });
  };

  const onPublish = (data: ExerciseFormValues) => {
    onSubmit({
      ...data,
      themeId: params.themeId,
      isPublished: !defaultValues.isPublished,
    });
  };

  return (
    <form className="m-form" onSubmit={handleSubmit(onSubmit)}>
      <Paper fullWidth className="m-form-fields">
        <h2 className="m-typography-h2">
          {defaultValues.title || t("e_learning_create_quizz")}
        </h2>
        <div className="m-form-field m-form-field-full-width">
          <label htmlFor="title" className="m-form-label">
            {t("title")}
          </label>
          <input
            id="title"
            name="title"
            className={classNames("m-form-input", {
              "m-form-input-error": Boolean(errors.title),
            })}
            ref={register({ required: requiredErrorMessage })}
            aria-invalid={Boolean(errors.title)}
          />
        </div>
        <span className="m-typography-body1">
          {t("e_learning_complementary_support")}
        </span>
        <div className="m-form-fields-content m-form-fields-content-col-2">
          <div className="m-form-field">
            <label htmlFor="video" className="m-form-label">
              {t("add_video")}
            </label>
            <input
              name="video"
              id="video"
              type="file"
              accept="video/*"
              className={classNames("m-form-input", {
                "m-form-input-error": Boolean(errors.video),
              })}
              ref={register({ required: requiredErrorMessage })}
              aria-invalid={Boolean(errors.video)}
            />
          </div>
          <div className="m-form-field">
            <label htmlFor="picto" className="m-form-label">
              {t("e_learning_picto_field")}
            </label>
            <input
              name="picto"
              id="picto"
              type="file"
              accept="image/png, image/jpeg"
              className={classNames("m-form-input", {
                "m-form-input-error": Boolean(errors.picto),
              })}
              ref={register({ required: requiredErrorMessage })}
              aria-invalid={Boolean(errors.picto)}
            />
          </div>
        </div>
        {fields?.map((question, index) => (
          <QuestionForm
            key={question.id}
            questionId={question.id || uuid()}
            index={index}
            removeQuestion={remove}
            defaultValues={{
              ...question,
              video: createDefaultVideoInputValue(question.videoId),
              correctionVideo: createDefaultVideoInputValue(
                question.correctionVideoId,
              ),
            }}
          />
        ))}
      </Paper>
      <Button
        size="large"
        variant="transparent"
        startIcon={<IoAddCircle />}
        onClick={onAddQuestionBtnClick}
      >
        <p className="m-typography-body1">{t("e_learning_add_question")}</p>
      </Button>
      <Paper fullWidth className="m-form-actions">
        <Button onClick={handleSubmit(onPublish)}>
          {defaultValues && defaultValues.isPublished
            ? t("unpublish")
            : t("publish")}
        </Button>
        <Button type="submit">{t("save")}</Button>
        <Button
          to={`/e-learning?theme="${params.themeId}"`}
          color="dangerous"
          variant="outlined"
        >
          {t("cancel")}
        </Button>
      </Paper>
    </form>
  );
};

export default ExerciseForm;

import { CheckboxGroupFieldOption } from "../../components/CheckboxGroupField/CheckboxGroupField";
import { DictionnaryEntry } from "../../presentation/dictionnary/dictionnaryEntry";
import { ELearningState, ICourse, IExercise, ITheme } from "./e-learning.types";
import { AppState } from "../../redux/types";
import { createSelector } from "reselect";

const selectELearningState = (state: AppState) => state.eLearning;

export const selectDictionnary = createSelector(
  selectELearningState,
  (eLearning: ELearningState) => eLearning.dictionnary,
);

export const selectDictionnaryEntry = (
  state: AppState,
  id: string,
): DictionnaryEntry | undefined =>
  state.eLearning.dictionnary?.find((entry) => entry.id === id);

export const selectThemes = createSelector(
  selectELearningState,
  (eLearning: ELearningState) => eLearning.themes?.filter(Boolean) || [],
);

export const selectTheme = createSelector(
  selectELearningState,
  (eLearning: ELearningState) => eLearning.theme,
);

export const selectLoading = createSelector(
  selectELearningState,
  (eLearning: ELearningState) => eLearning.loading,
);

export const selectSession = createSelector(
  selectELearningState,
  (eLearning: ELearningState) => eLearning.session,
);

export const selectSessionScore = createSelector(
  selectELearningState,
  (eLearning: ELearningState) => eLearning.sessionScore,
);

export const selectThemeById =
  (id?: string) =>
  (state: AppState): undefined | ITheme =>
    selectThemes(state).find((theme) => theme.id === id);

export const selectCoursesByThemeId =
  (id?: string) =>
  (state: AppState): ICourse[] => {
    const theme = selectThemeById(id)(state);
    if (!theme) return [];
    return theme.courses || [];
  };

export const selectCourseOptionsByThemeId =
  (id?: string) =>
  (state: AppState): CheckboxGroupFieldOption[] => {
    const theme = selectThemeById(id)(state);
    if (!theme) return [];
    return (
      theme.courses?.map((course) => ({
        label: course.title,
        value: course.id,
      })) || []
    );
  };

export const selectCourseById =
  (themeId?: string, courseId?: string) =>
  (state: AppState): ICourse | undefined => {
    const theme = selectThemeById(themeId)(state);
    if (!theme) return;
    return theme.courses?.find((course) => course.id === courseId);
  };

export const selectExerciseById =
  (themeId?: string, exerciseId?: string) =>
  (state: AppState): IExercise | undefined => {
    const theme = selectThemeById(themeId)(state);
    if (!theme) return;
    return theme.exercises?.find((exercise) => exercise.id === exerciseId);
  };

export const selectExercisesByThemeId =
  (id?: string) =>
  (state: AppState): IExercise[] => {
    const theme = selectThemeById(id)(state);
    if (!theme) return [];
    return theme.exercises || [];
  };

export const selectExercises = createSelector(
  selectTheme,
  (theme) => theme?.exercises || [],
);

export const selectBeneficiaryCourses = createSelector(
  selectELearningState,
  (eLearning: ELearningState) => eLearning.courses,
);

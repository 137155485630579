import { useController, useFormContext, useWatch } from "react-hook-form";
import { ComponentProps, FC, ReactNode } from "react";
import "../../components/RadioInput/RadioInput.scss";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import "./CheckboxGroupField.scss";

export type CheckboxGroupFieldOption = {
  label?: string;
  value: string;
} & Record<string, unknown>;

export interface CheckboxGroupFieldProps extends ComponentProps<"input"> {
  label?: string;
  name: string;
  options: CheckboxGroupFieldOption[];
  renderOption?: (option: CheckboxGroupFieldOption) => ReactNode;
  defaultValue?: string[];
}

const CheckboxGroupField: FC<CheckboxGroupFieldProps> = ({
  name,
  options,
  label,
  renderOption,
  className,
  defaultValue = [],
  required,
  ...props
}) => {
  const { t } = useTranslation();

  const { control, errors } = useFormContext();

  const {
    field: { ref, value, onChange, ...inputProps },
  } = useController({
    name,
    control,
    defaultValue,
    rules: {
      validate: (value) =>
        !required ||
        value.length > 0 ||
        t("error_at_least_one_selected_element"),
    },
  });

  const values = useWatch<string[]>({ control, name }) || defaultValue;

  const toggle = (value: string) => () => {
    const newValues = [...values];

    if (newValues.length > 0) {
      const index = newValues.findIndex((newValue) => newValue === value);

      if (index === -1) {
        newValues.push(value);
      } else {
        newValues.splice(index, 1);
      }
    } else {
      newValues.push(value);
    }

    onChange(newValues);
  };

  return (
    <fieldset
      className={classNames(
        "checkbox-group-field m-form-field m-form-field-full-width",
        className,
      )}
    >
      {label && <label className="m-form-label">{label}</label>}
      {options.map((option) => (
        <div key={option.value} className="checkbox-input-wrapper">
          <input
            id={option.value}
            type="checkbox"
            className="checkbox-input"
            aria-invalid={Boolean(errors[name])}
            checked={value?.some((checked) => checked === option.value)}
            {...inputProps}
            ref={ref}
            onChange={toggle(option.value)}
            {...props}
          />
          <label htmlFor={option.value} className="m-form-label">
            {option.label}
          </label>
          <div className="checkbox-input-right-content">
            {renderOption && renderOption(option)}
          </div>
        </div>
      ))}
      <p className="m-form-helper-text m-form-error">{errors[name]?.message}</p>
    </fieldset>
  );
};

export default CheckboxGroupField;

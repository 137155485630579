import {
  CREATE_THEME,
  GET_ADMIN_THEMES,
  GET_DICTIONNARY_ENTRIES,
  GET_THEME_AND_COURSES,
  GET_THEME_AND_EXERCISES,
  DELETE_THEME,
  UPDATE_THEME,
  GET_BENEFICIARY_THEMES,
  CREATE_COURSE,
  UPDATE_COURSE,
  ADD_DICTIONNARY_ENTRY,
  UPDATE_DICTIONNARY_ENTRY,
  DELETE_DICTIONNARY_ENTRY,
  PUBLISH_ALL_COURSES,
  PUBLISH_ALL_EXERCISES,
  GET_COURSES,
  DELETE_EXERCISE,
  UPDATE_QUESTION,
  UPDATE_EXERCISE,
  UPDATE_ANSWER,
  ITheme,
  ELearningActionsType,
  IQuestion,
  IAnswer,
  GET_BENEFICIARY_EXERCISES,
  GET_BENEFICIARY_PRACTICE_SESSION,
  GET_BENEFICIARY_EXAM_SESSION,
  UPDATE_EXERCISE_BENEFICIARY,
  IExerciseBeneficiary,
  GET_BENEFICIARY_PRACTICE_SESSION_SCORE,
  GET_BENEFICIARY_EXAM_SESSION_SCORE,
  ISessionScore,
  UPDATE_DICTIONNARY_ENTRY_SUCCESS,
  ADD_DICTIONNARY_ENTRY_SUCCESS,
  CreateThemeActionType,
  DELETE_DICTIONNARY_ENTRY_SUCCESS,
  DELETE_DICTIONNARY_ENTRY_FAILURE,
  CREATE_THEME_SUCCESS,
  UPDATE_THEME_SUCCESS,
  UpdateThemeActionType,
  CreateCourseActionType,
  CREATE_COURSE_SUCCESS,
  UpdateCourseActionType,
  UPDATE_COURSE_SUCCESS,
  DELETE_COURSE,
  DELETE_COURSE_SUCCESS,
  PUBLISH_COURSE,
  PUBLISH_COURSE_SUCCESS,
  CreateExerciseActionType,
  UpdateExerciseActionType,
  CREATE_EXERCISE,
  CREATE_EXERCISE_SUCCESS,
  UPDATE_EXERCISE_SUCCESS,
} from "../../domain/e-learning/e-learning.types";
import {
  call,
  CallEffect,
  ForkEffect,
  put,
  PutEffect,
  takeLatest,
  takeEvery,
} from "redux-saga/effects";
import { DictionnaryEntry } from "../../presentation/dictionnary/dictionnaryEntry";
import ELearningApi from "./e-learning.api";

import {
  createThemeSuccess,
  createThemeFailure,
  getAdminThemesSuccess,
  getAdminThemesFailure,
  getDictionnaryEntriesSuccess,
  getDictionnaryEntriesFailure,
  getThemeAndCoursesSuccess,
  getThemeAndCoursesFailure,
  getThemeAndExercisesSuccess,
  getThemeAndExercisesFailure,
  deleteThemeSuccess,
  deleteThemeFailure,
  updateThemeSuccess,
  updateThemeFailure,
  getBeneficiaryThemesSuccess,
  getBeneficiaryThemesFailure,
  updateCourseSuccess,
  updateCourseFailure,
  deleteDictionnaryEntrySuccess,
  deleteDictionnaryEntryFailure,
  updateDictionnaryEntrySuccess,
  updateDictionnaryEntryFailure,
  addDictionnaryEntrySuccess,
  addDictionnaryEntryFailure,
  publishAllCourseSuccess,
  publishAllCourseFailure,
  publishAllExercisesSuccess,
  publishAllExercisesFailure,
  getCoursesSuccess,
  getCoursesFailure,
  deleteExerciseSuccess,
  deleteExerciseFailure,
  updateQuestionSuccess,
  updateQuestionFailure,
  updateExerciseSuccess,
  updateExerciseFailure,
  updateAnswerSuccess,
  updateAnswerFailure,
  getBeneficiaryExercisesSuccess,
  getBeneficiaryExercisesFailure,
  getBeneficiaryPracticeSessionSuccess,
  getBeneficiaryPracticeSessionFailure,
  getBeneficiaryExamSessionSuccess,
  getBeneficiaryExamSessionFailure,
  updateExerciseBeneficiarySuccess,
  updateExerciseBeneficiaryFailure,
  getBeneficiaryPracticeSessionScoreSuccess,
  getBeneficiaryPracticeSessionScoreFailure,
  getBeneficiaryExamSessionScoreSuccess,
  getBeneficiaryExamSessionScoreFailure,
  createCourseSuccess,
  createCourseFailure,
  deleteCourseSuccess,
  deleteCourseFailure,
  publishCourseSuccess,
  publishCourseFailure,
  createExerciseSuccess,
  createExerciseFailure,
} from "../../domain/e-learning/e-learning.actions";
import { showSnackBar } from "../../domain/interactionsWithUsers/interactionsWithUsers.actions";
import { ApiExecutorType } from "../../utils/fetchApi/apiExecutor.type";
import getErrorMessage from "../../utils/getErrorMessage";
import { INIT } from "../../redux/root-action";

export default function ELearningSaga(apiExecutor: ApiExecutorType): {
  eLearningSaga: () => Generator<ForkEffect<never>, void, unknown>;
} {
  const eLearningApi = ELearningApi(apiExecutor);

  function* createTheme(action: CreateThemeActionType) {
    try {
      if (action.theme) {
        const { body: theme } = yield call(
          eLearningApi.createTheme,
          action.theme,
        );
        const snackbar = {
          status: true,
          type: "success",
          message: "successfull_theme_created",
        };
        yield put(createThemeSuccess(theme));
        yield put(showSnackBar(snackbar));
      }
    } catch (error) {
      const snackbar = {
        type: "error",
        message: getErrorMessage(error),
        status: true,
      };

      yield put(showSnackBar(snackbar));

      yield put(createThemeFailure("Une erreur est survenue"));
    }
  }

  function* createCourse(action: CreateCourseActionType) {
    try {
      if (action.course) {
        const { body: course } = yield call(
          eLearningApi.createCourse,
          action.course,
        );
        const snackbar = {
          status: true,
          type: "success",
          message: "successfull_course_created",
        };
        yield put(createCourseSuccess(course));
        yield put(showSnackBar(snackbar));
      }
    } catch (error) {
      const snackbar = {
        type: "error",
        message: getErrorMessage(error),
        status: true,
      };

      yield put(showSnackBar(snackbar));

      yield put(createCourseFailure("Une erreur est survenue"));
    }
  }

  function* getAdminThemes(): Generator<
    | CallEffect<{
        body: ITheme[];
        status: number;
      }>
    | PutEffect<ELearningActionsType>,
    void,
    {
      body: ITheme[];
    }
  > {
    try {
      const { body: themes } = yield call(eLearningApi.getAdminThemes);
      yield put(getAdminThemesSuccess(themes));
    } catch (error) {
      yield put(getAdminThemesFailure("Une erreur est survenue"));
    }
  }

  function* getDictionnaryEntries(): Generator<
    | CallEffect<{
        body: DictionnaryEntry[];
        status: number;
      }>
    | PutEffect<ELearningActionsType>,
    void,
    {
      body: DictionnaryEntry[];
    }
  > {
    try {
      const { body: dictionnaryEntries } = yield call(
        eLearningApi.getDictionnaryEntries,
      );
      yield put(getDictionnaryEntriesSuccess(dictionnaryEntries));
    } catch (error) {
      yield put(getDictionnaryEntriesFailure("Une erreur est survenue"));
    }
  }

  function* getThemeAndCourses(action: ELearningActionsType) {
    try {
      const { body: theme } = yield call(
        eLearningApi.getThemeAndCourses,
        action?.id as string,
      );
      yield put(getThemeAndCoursesSuccess(theme));
    } catch (error) {
      yield put(getThemeAndCoursesFailure("Une erreur est survenue"));
    }
  }

  function* getThemeAndExercises(action: ELearningActionsType) {
    try {
      const { body: theme } = yield call(
        eLearningApi.getThemeAndExercises,
        action?.id as string,
      );
      yield put(getThemeAndExercisesSuccess(theme));
    } catch (error) {
      yield put(getThemeAndExercisesFailure("Une erreur est survenue"));
    }
  }

  function* deleteTheme(action: ELearningActionsType) {
    try {
      const { body: theme } = yield call(
        eLearningApi.deleteTheme,
        action.id as string,
      );
      const snackbar = {
        status: true,
        type: "success",
        message: "successfull_theme_deleted",
      };
      yield put(deleteThemeSuccess(theme));
      yield put(showSnackBar(snackbar));
    } catch (error) {
      const snackbar = {
        type: "error",
        message: getErrorMessage(error),
        status: true,
      };

      yield put(showSnackBar(snackbar));
      yield put(deleteThemeFailure("Une erreur est survenue"));
    }
  }

  function* deleteCourse(action: ELearningActionsType) {
    try {
      const { body: course } = yield call(
        eLearningApi.deleteCourse,
        action.id as string,
      );
      const snackbar = {
        status: true,
        type: "success",
        message: "successfull_course_deleted",
      };
      yield put(deleteCourseSuccess(course));
      yield put(showSnackBar(snackbar));
    } catch (error) {
      const snackbar = {
        type: "error",
        message: getErrorMessage(error),
        status: true,
      };

      yield put(showSnackBar(snackbar));
      yield put(deleteCourseFailure("Une erreur est survenue"));
    }
  }

  function* publishCourse(action: ELearningActionsType) {
    try {
      const { body: course } = yield call(
        eLearningApi.publishCourse,
        action.id as string,
      );
      const snackbar = {
        status: true,
        type: "success",
        message: "successfull_course_published",
      };
      yield put(publishCourseSuccess(course));
      yield put(showSnackBar(snackbar));
    } catch (error) {
      const snackbar = {
        type: "error",
        message: getErrorMessage(error),
        status: true,
      };

      yield put(showSnackBar(snackbar));
      yield put(publishCourseFailure("Une erreur est survenue"));
    }
  }

  function* updateTheme(action: UpdateThemeActionType) {
    try {
      if (action && action.theme) {
        const snackbar = {
          status: true,
          type: "success",
          message: "successfull_theme_modified",
        };
        const { body: theme } = yield call(
          eLearningApi.updateTheme,
          action.id,
          action.theme,
        );
        yield put(updateThemeSuccess(theme));
        yield put(showSnackBar(snackbar));
      } else throw new Error();
    } catch (error) {
      const snackbar = {
        type: "error",
        message: getErrorMessage(error),
        status: true,
      };

      yield put(showSnackBar(snackbar));
      yield put(updateThemeFailure("Une erreur est survenue"));
    }
  }

  function* getBeneficiaryThemes(): Generator<
    | CallEffect<{
        body: ITheme[];
        status: number;
      }>
    | PutEffect<ELearningActionsType>,
    void,
    {
      body: ITheme[];
    }
  > {
    try {
      const { body: themes } = yield call(eLearningApi.getBeneficiaryThemes);
      yield put(getBeneficiaryThemesSuccess(themes));
    } catch (error) {
      yield put(getBeneficiaryThemesFailure("Une erreur est survenue"));
    }
  }

  function* updateCourse(action: UpdateCourseActionType) {
    try {
      if (action && action.course) {
        const snackbar = {
          status: true,
          type: "success",
          message: "successfull_course_modified",
        };
        const { body: course } = yield call(
          eLearningApi.updateCourse,
          action.id,
          action.course,
        );
        yield put(updateCourseSuccess(course));
        yield put(showSnackBar(snackbar));
      } else throw new Error();
    } catch (error) {
      const snackbar = {
        type: "error",
        message: getErrorMessage(error),
        status: true,
      };
      yield put(updateCourseFailure("Une erreur est survenue"));

      yield put(showSnackBar(snackbar));
    }
  }

  function* addDictionnaryEntry(action: ELearningActionsType) {
    try {
      if (action.dictionnaryEntry) {
        const { body: updatedDictionnaryEntry } = yield call(
          eLearningApi.addDictionnaryEntry,
          action.dictionnaryEntry,
        );
        yield put(addDictionnaryEntrySuccess(updatedDictionnaryEntry));
        const snackbar = {
          status: true,
          type: "success",
          message: "successfull_dictionnary_entry_created",
        };
        yield put(showSnackBar(snackbar));
      }
    } catch (error) {
      const snackbar = {
        type: "error",
        message: getErrorMessage(error),
        status: true,
      };
      yield put(showSnackBar(snackbar));
      yield put(addDictionnaryEntryFailure("Une erreur est survenue"));
    }
  }

  function* deleteDictionnaryEntry(action: ELearningActionsType) {
    try {
      if (action.dictionnaryEntryId) {
        yield call(
          eLearningApi.deleteDictionnaryEntry,
          action.dictionnaryEntryId,
        );
        yield put(deleteDictionnaryEntrySuccess(action.dictionnaryEntryId));
        const snackbar = {
          status: true,
          type: "success",
          message: "successfull_dictionnary_entry_deleted",
        };
        yield put(showSnackBar(snackbar));
      }
    } catch (error) {
      const snackbar = {
        type: "error",
        message: getErrorMessage(error),
        status: true,
      };

      yield put(showSnackBar(snackbar));
      yield put(deleteDictionnaryEntryFailure("Une erreur est survenue"));
    }
  }

  function* updateDictionnaryEntry(action: ELearningActionsType) {
    try {
      const snackbar = {
        status: true,
        type: "success",
        message: "successfull_dictionnary_entry_updated",
      };
      if (action.dictionnaryEntry) {
        const { body: dictionnaryEntry } = yield call(
          eLearningApi.updateDictionnaryEntry,
          action.dictionnaryEntry,
        );
        yield put(
          updateDictionnaryEntrySuccess(dictionnaryEntry as DictionnaryEntry),
        );
        yield put(showSnackBar(snackbar));
      }
    } catch (error) {
      const snackbar = {
        type: "error",
        message: getErrorMessage(error),
        status: true,
      };

      yield put(showSnackBar(snackbar));
      yield put(updateDictionnaryEntryFailure("Une erreur est survenue"));
    }
  }

  function* publishAllCourses(action: ELearningActionsType) {
    try {
      const publishData = {
        themeId: action.themeId as string,
        isPublished: action.isPublished as boolean,
      };
      let message;
      if (action.isPublished) {
        message = "successfull_published_courses";
      } else {
        message = "successfull_not_published_courses";
      }
      const snackbar = {
        status: true,
        type: "success",
        message,
      };
      yield call(eLearningApi.publishAllCourses, publishData);
      yield put(publishAllCourseSuccess(action.isPublished as boolean));
      yield call(getAdminThemes);
      yield put(showSnackBar(snackbar));
    } catch (error) {
      const snackbar = {
        type: "error",
        message: getErrorMessage(error),
        status: true,
      };

      yield put(showSnackBar(snackbar));
      yield put(publishAllCourseFailure("Une erreur est survenue"));
    }
  }

  function* publishAllExercises(action: ELearningActionsType) {
    try {
      const publishData = {
        themeId: action.themeId as string,
        isPublished: action.isPublished as boolean,
      };
      let message;
      if (action.isPublished) {
        message = "successfull_published_exercises";
      } else {
        message = "successfull_not_published_exercises";
      }
      const snackbar = {
        status: true,
        type: "success",
        message,
      };
      yield call(eLearningApi.publishAllExercices, publishData);
      yield put(publishAllExercisesSuccess(action.isPublished as boolean));
      yield call(getAdminThemes);
      yield put(showSnackBar(snackbar));
    } catch (error) {
      const snackbar = {
        type: "error",
        message: getErrorMessage(error),
        status: true,
      };

      yield put(showSnackBar(snackbar));
      yield put(publishAllExercisesFailure("Une erreur est survenue"));
    }
  }

  function* getCourses(action: ELearningActionsType) {
    try {
      const { body: courses } = yield call(
        eLearningApi.getCourses,
        action?.themeId as string,
      );
      yield put(getCoursesSuccess(courses));
    } catch (error) {
      yield put(getCoursesFailure("Une erreur est survenue"));
    }
  }

  function* createExercise(action: CreateExerciseActionType) {
    try {
      const snackbar = {
        status: true,
        type: "success",
        message: "successfull_exercise_created",
      };
      const { body: exercise } = yield call(
        eLearningApi.createExercise,
        action.exercise,
      );
      yield put(createExerciseSuccess(exercise));
      yield put(showSnackBar(snackbar));
    } catch (error) {
      const snackbar = {
        type: "error",
        message: getErrorMessage(error),
        status: true,
      };
      yield put(createExerciseFailure("Une erreur est survenue"));

      yield put(showSnackBar(snackbar));
    }
  }

  function* deleteExercise(action: ELearningActionsType) {
    try {
      const snackbar = {
        status: true,
        type: "success",
        message: "successfull_exercise_deleted",
      };
      const exerciseId = action.id as string;
      yield call(eLearningApi.deleteExercise, exerciseId as string);
      yield put(deleteExerciseSuccess(exerciseId as string));
      yield put(showSnackBar(snackbar));
    } catch (error) {
      const snackbar = {
        type: "error",
        message: getErrorMessage(error),
        status: true,
      };

      yield put(showSnackBar(snackbar));
      yield put(deleteExerciseFailure("Une erreur est survenue"));
    }
  }

  function* updateExercise(action: UpdateExerciseActionType) {
    try {
      if (action.exercise) {
        const { body: exercise } = yield call(
          eLearningApi.updateExercise,
          action.id,
          action.exercise,
        );
        yield put(updateExerciseSuccess(exercise));
        const snackbar = {
          status: true,
          type: "success",
          message: "successfull_exercise_updated",
        };
        yield put(showSnackBar(snackbar));
      }
    } catch (error) {
      const snackbar = {
        status: true,
        type: "error",
        message: getErrorMessage(error),
      };
      yield put(showSnackBar(snackbar));

      yield put(updateExerciseFailure("Une erreur est survenue"));
    }
  }
  function* updateQuestion(action: ELearningActionsType) {
    try {
      const snackbar = {
        status: true,
        type: "success",
        message: "successfull_exercise_modified",
      };
      if (action.question) {
        const { body: question } = yield call(
          eLearningApi.updateQuestion,
          action.question,
        );
        yield put(updateQuestionSuccess(question as IQuestion));
        yield put(showSnackBar(snackbar));
      }
    } catch (error) {
      yield put(updateQuestionFailure("Une erreur est survenue"));
    }
  }
  function* updateAnswer(action: ELearningActionsType) {
    try {
      const snackbar = {
        status: true,
        type: "success",
        message: "successfull_exercise_modified",
      };
      if (action.answer) {
        const { body: answer } = yield call(
          eLearningApi.updateAnswer,
          action.answer,
        );
        yield put(updateAnswerSuccess(answer as IAnswer));
        yield put(showSnackBar(snackbar));
      }
    } catch (error) {
      yield put(updateAnswerFailure("Une erreur est survenue"));
    }
  }

  function* getBeneficiaryExercises(action: ELearningActionsType) {
    try {
      const { body: theme } = yield call(
        eLearningApi.getBeneficiaryExercises,
        action?.id as string,
      );
      yield put(getBeneficiaryExercisesSuccess(theme));
    } catch (error) {
      yield put(getBeneficiaryExercisesFailure("Une erreur est survenue"));
    }
  }

  function* getBeneficiaryPracticeSession(action: ELearningActionsType) {
    try {
      const data = {
        userId: action.userId as string,
        themeId: action.themeId as string,
      };
      const { body: session } = yield call(
        eLearningApi.getBeneficiaryPracticeSession,
        data,
      );
      yield put(getBeneficiaryPracticeSessionSuccess(session));
    } catch (error) {
      yield put(
        getBeneficiaryPracticeSessionFailure("Une erreur est survenue"),
      );
    }
  }

  function* getBeneficiaryExamSession(action: ELearningActionsType) {
    try {
      const data = {
        userId: action.userId as string,
      };
      const { body: session } = yield call(
        eLearningApi.getBeneficiaryExamSession,
        data,
      );
      yield put(getBeneficiaryExamSessionSuccess(session));
    } catch (error) {
      yield put(getBeneficiaryExamSessionFailure("Une erreur est survenue"));
    }
  }

  function* updateExerciseBeneficiary(action: ELearningActionsType) {
    try {
      if (action && action.exerciseBeneficiary) {
        const { body: exerciseBeneficiary } = yield call(
          eLearningApi.updateExerciseBeneficiary,
          action.exerciseBeneficiary,
        );
        yield put(
          updateExerciseBeneficiarySuccess(
            exerciseBeneficiary as IExerciseBeneficiary,
          ),
        );
      }
    } catch (error) {
      yield put(updateExerciseBeneficiaryFailure("Une erreur est survenue"));
    }
  }

  function* getBeneficiaryPracticeSessionScore(action: ELearningActionsType) {
    try {
      const { body: sessionScore } = yield call(
        eLearningApi.getBeneficiaryPracticeSessionScore,
        action?.id as string,
      );
      yield put(
        getBeneficiaryPracticeSessionScoreSuccess(
          sessionScore as ISessionScore,
        ),
      );
    } catch (error) {
      yield put(
        getBeneficiaryPracticeSessionScoreFailure("Une erreur est survenue"),
      );
    }
  }

  function* getBeneficiaryExamSessionScore(action: ELearningActionsType) {
    try {
      const { body: sessionScore } = yield call(
        eLearningApi.getBeneficiaryExamSessionScore,
        action?.id as string,
      );
      yield put(
        getBeneficiaryExamSessionScoreSuccess(sessionScore as ISessionScore),
      );
    } catch (error) {
      yield put(
        getBeneficiaryExamSessionScoreFailure("Une erreur est survenue"),
      );
    }
  }

  function* eLearningSaga(): Generator<ForkEffect<never>, void, unknown> {
    yield takeLatest(CREATE_THEME, createTheme);
    yield takeLatest(CREATE_THEME_SUCCESS, getAdminThemes);
    yield takeLatest(UPDATE_THEME_SUCCESS, getAdminThemes);
    yield takeLatest(GET_ADMIN_THEMES, getAdminThemes);
    yield takeLatest(INIT, getAdminThemes);
    yield takeLatest(GET_THEME_AND_COURSES, getThemeAndCourses);
    yield takeLatest(GET_THEME_AND_EXERCISES, getThemeAndExercises);
    yield takeLatest(DELETE_THEME, deleteTheme);
    yield takeLatest(UPDATE_THEME, updateTheme);
    yield takeLatest(GET_BENEFICIARY_THEMES, getBeneficiaryThemes);
    yield takeLatest(CREATE_COURSE, createCourse);
    yield takeLatest(CREATE_COURSE_SUCCESS, getAdminThemes);
    yield takeLatest(UPDATE_COURSE, updateCourse);
    yield takeLatest(UPDATE_COURSE_SUCCESS, getAdminThemes);
    yield takeLatest(DELETE_COURSE, deleteCourse);
    yield takeLatest(DELETE_COURSE_SUCCESS, getAdminThemes);
    yield takeLatest(PUBLISH_COURSE, publishCourse);
    yield takeLatest(PUBLISH_COURSE_SUCCESS, getAdminThemes);
    yield takeLatest(CREATE_EXERCISE, createExercise);
    yield takeLatest(CREATE_EXERCISE_SUCCESS, getAdminThemes);
    yield takeLatest(ADD_DICTIONNARY_ENTRY, addDictionnaryEntry);
    yield takeLatest(DELETE_DICTIONNARY_ENTRY, deleteDictionnaryEntry);
    yield takeLatest(UPDATE_DICTIONNARY_ENTRY, updateDictionnaryEntry);
    yield takeLatest(UPDATE_DICTIONNARY_ENTRY_SUCCESS, getDictionnaryEntries);
    yield takeLatest(DELETE_DICTIONNARY_ENTRY_SUCCESS, getDictionnaryEntries);
    yield takeLatest(DELETE_DICTIONNARY_ENTRY_FAILURE, getDictionnaryEntries);
    yield takeLatest(ADD_DICTIONNARY_ENTRY_SUCCESS, getDictionnaryEntries);
    yield takeLatest(GET_DICTIONNARY_ENTRIES, getDictionnaryEntries);
    yield takeLatest(INIT, getDictionnaryEntries);
    yield takeLatest(PUBLISH_ALL_COURSES, publishAllCourses);
    yield takeLatest(PUBLISH_ALL_EXERCISES, publishAllExercises);
    yield takeLatest(GET_COURSES, getCourses);
    yield takeLatest(DELETE_EXERCISE, deleteExercise);
    yield takeLatest(UPDATE_EXERCISE, updateExercise);
    yield takeLatest(UPDATE_EXERCISE_SUCCESS, getAdminThemes);
    yield takeLatest(UPDATE_QUESTION, updateQuestion);
    yield takeEvery(UPDATE_ANSWER, updateAnswer);
    yield takeEvery(GET_BENEFICIARY_EXERCISES, getBeneficiaryExercises);
    yield takeEvery(
      GET_BENEFICIARY_PRACTICE_SESSION,
      getBeneficiaryPracticeSession,
    );
    yield takeEvery(GET_BENEFICIARY_EXAM_SESSION, getBeneficiaryExamSession);
    yield takeEvery(UPDATE_EXERCISE_BENEFICIARY, updateExerciseBeneficiary);
    yield takeLatest(
      GET_BENEFICIARY_PRACTICE_SESSION_SCORE,
      getBeneficiaryPracticeSessionScore,
    );
    yield takeLatest(
      GET_BENEFICIARY_EXAM_SESSION_SCORE,
      getBeneficiaryExamSessionScore,
    );
  }

  return { eLearningSaga };
}

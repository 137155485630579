import {
  deleteCourse,
  publishCourse,
} from "../../domain/e-learning/e-learning.actions";
import { DictionnaryEntry } from "../../presentation/dictionnary/dictionnaryEntry";
import { ICourse, IExercise } from "../../domain/e-learning/e-learning.types";
import ButtonDropdown from "../ButtonDropdown/ButtonDropdown";
import { IoAddCircle, IoCreate } from "react-icons/io5";
import IconButton from "../IconButton/IconButton";
import { FC, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import mapWithId from "../../utils/mapWithId";
import { useDispatch } from "react-redux";
import Button from "../Button/Button";
import Chip from "../Chip/Chip";
import "./Course.scss";

interface CourseProps
  extends Pick<ICourse, "id" | "title" | "isPublished" | "themeId" | "order"> {
  dictionnaryEntries?: DictionnaryEntry[];
  exercises?: IExercise[];
}

const Course: FC<CourseProps> = ({
  id,
  themeId,
  isPublished,
  title,
  dictionnaryEntries = [],
  exercises = [],
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onDelete = useCallback(() => {
    dispatch(deleteCourse(id));
  }, [dispatch, id]);

  const actions = useMemo(
    () =>
      mapWithId(
        isPublished
          ? [
              {
                label: "delete",
                onClick: onDelete,
              },
            ]
          : [
              {
                label: "publish",
                onClick: () => {
                  dispatch(publishCourse(id));
                },
              },
              {
                label: "delete",
                onClick: onDelete,
              },
            ],
      ),
    [dispatch, id, isPublished, onDelete],
  );

  if (!title) {
    return <p className="m-typography-body2">{t("Aucun cours")}</p>;
  }
  return (
    <li className="course">
      <div className="course-header">
        <h2 className="course-title">{title}</h2>
        <div className="course-actions">
          <ButtonDropdown size="small" actions={actions}>
            {t("draft")}
          </ButtonDropdown>
          <IconButton to={`/e-learning/${themeId}/courses/${id}`}>
            <IoCreate />
          </IconButton>
        </div>
      </div>
      <div className="course-content">
        {dictionnaryEntries.length > 0 && (
          <div className="course-field">
            <p className="m-typography-body2">{t("dictionnary_entries")}</p>
            <div className="course-field-value">
              {dictionnaryEntries.map((dictionnaryEntry) => (
                <Chip key={dictionnaryEntry.id}>{dictionnaryEntry.word}</Chip>
              ))}
            </div>
          </div>
        )}
        {exercises.length >= 0 && (
          <div className="course-field">
            <p className="m-typography-body2">{t("exercises")}</p>
            <div className="course-field-value">
              {exercises.map((exercise) => (
                <Chip
                  key={exercise.id}
                  to={`/e-learning/${themeId}/exercises/${exercise.id}`}
                >
                  {exercise.title}
                </Chip>
              ))}
              <Button
                startIcon={<IoAddCircle />}
                size="small"
                variant="outlined"
                to={`/e-learning/${themeId}/exercises/add`}
              >
                {t("e_learning_add_exercises")}
              </Button>
            </div>
          </div>
        )}
      </div>
    </li>
  );
};

export default Course;

import { ComponentProps, FC, ReactNode, Ref } from "react";
import RadioInput from "../RadioInput/RadioInput";
import classNames from "classnames";
import "./RadioGroupField.scss";

export type RadioGroupFieldOption = {
  label?: string;
  value: string;
} & Record<string, unknown>;

export interface RadioGroupFieldProps extends ComponentProps<"input"> {
  label?: string;
  options: RadioGroupFieldOption[];
  register?: Ref<HTMLInputElement>;
  renderOption: (option: RadioGroupFieldOption) => ReactNode;
}

const RadioGroupField: FC<RadioGroupFieldProps> = ({
  options,
  label,
  renderOption,
  className,
  disabled,
  ...props
}) => {
  return (
    <fieldset
      className={classNames(
        "radio-group-field m-form-field m-form-field-full-width",
        className,
      )}
    >
      {label && <label className="m-form-label">{label}</label>}
      {options.map((option) => {
        return (
          <RadioInput
            key={option.value || option.label}
            {...props}
            id={option.value || option.label}
            type="radio"
            value={option.value}
            checked={props.register ? undefined : props.value === option.value}
            label={option.label}
            disabled={disabled || !option.value}
          >
            {renderOption && renderOption(option)}
          </RadioInput>
        );
      })}
    </fieldset>
  );
};

export default RadioGroupField;

import { CreateCourseActionPayload } from "../domain/e-learning/e-learning.types";
import { createCourse } from "../domain/e-learning/e-learning.actions";
import StepNav, { Step } from "../components/StepBar/StepNav";
import CourseForm from "../components/CourseForm/CourseForm";

import { FileDragAndDropProvider } from "../components/DragAndDrop/DragAndDropContext";
import ThemeHeaderCard from "../components/ThemeHeaderCard/ThemeHeaderCard";
import FileUpload from "../components/FileUpload/FileUpload";
import { generatePath, useParams } from "react-router-dom";
import LeftSidebar from "../components/Page/LeftSidebar";
import Content from "../components/Page/Content";
import Topbar from "../components/Page/Topbar";
import useNavigate from "../utils/useNavigate";
import { useTranslation } from "react-i18next";
import Page from "../components/Page/Page";
import mapWithId from "../utils/mapWithId";
import { useDispatch } from "react-redux";
import { FC, useMemo } from "react";
import "./AddCourse.scss";

const AddCourse: FC = () => {
  const { t } = useTranslation();
  const params = useParams<{ themeId: string }>();
  const dispatch = useDispatch();
  const navigate = useNavigate(`/e-learning?theme="${params.themeId}"`);
  const onSubmit = (data: CreateCourseActionPayload) => {
    dispatch(createCourse(data));
    navigate();
  };

  const steps = useMemo<Step[]>(
    () =>
      mapWithId([
        {
          label: "e_learning_edit",
          to: generatePath("/e-learning/:id/edit", { id: params.themeId }),
        },
        {
          label: "e_learning_add_courses",
          to: generatePath("/e-learning/:id/courses/add", {
            id: params.themeId,
          }),
        },
        {
          label: "e_learning_add_exercises",
          to: generatePath("/e-learning/:id/exercises/add", {
            id: params.themeId,
          }),
        },
      ]),
    [params],
  );

  return (
    <Page disabledGutters>
      <FileDragAndDropProvider>
        <Topbar className="add-course-topbar">
          <StepNav steps={steps} />
        </Topbar>
        <LeftSidebar className="add-course-left-sidebar">
          <ThemeHeaderCard id={params.themeId} />
          <FileUpload
            label={t("import_file")}
            accept={"video/mp4,video/webm"}
            multiple
          />
        </LeftSidebar>
        <Content>
          <CourseForm title="e_learning_add_courses" onSubmit={onSubmit} />
        </Content>
      </FileDragAndDropProvider>
    </Page>
  );
};

export default AddCourse;

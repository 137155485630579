import {
  CREATE_THEME,
  CREATE_THEME_SUCCESS,
  CREATE_THEME_FAILURE,
  GET_ADMIN_THEMES,
  GET_ADMIN_THEMES_SUCCESS,
  GET_ADMIN_THEMES_FAILURE,
  GET_DICTIONNARY_ENTRIES,
  GET_DICTIONNARY_ENTRIES_SUCCESS,
  GET_DICTIONNARY_ENTRIES_FAILURE,
  GET_THEME_AND_COURSES,
  GET_THEME_AND_COURSES_SUCCESS,
  GET_THEME_AND_COURSES_FAILURE,
  GET_THEME_AND_EXERCISES,
  GET_THEME_AND_EXERCISES_SUCCESS,
  GET_THEME_AND_EXERCISES_FAILURE,
  RESET_THEME,
  DELETE_THEME,
  DELETE_THEME_SUCCESS,
  DELETE_THEME_FAILURE,
  UPDATE_THEME,
  UPDATE_THEME_SUCCESS,
  UPDATE_THEME_FAILURE,
  GET_BENEFICIARY_THEMES,
  GET_BENEFICIARY_THEMES_SUCCESS,
  GET_BENEFICIARY_THEMES_FAILURE,
  CREATE_COURSE,
  CREATE_COURSE_SUCCESS,
  CREATE_COURSE_FAILURE,
  DELETE_COURSE,
  DELETE_COURSE_SUCCESS,
  DELETE_COURSE_FAILURE,
  SET_ADD_MODE,
  UPDATE_COURSE,
  UPDATE_COURSE_SUCCESS,
  UPDATE_COURSE_FAILURE,
  ADD_DICTIONNARY_ENTRY,
  ADD_DICTIONNARY_ENTRY_FAILURE,
  DELETE_DICTIONNARY_ENTRY,
  DELETE_DICTIONNARY_ENTRY_FAILURE,
  UPDATE_DICTIONNARY_ENTRY,
  UPDATE_DICTIONNARY_ENTRY_FAILURE,
  REORDER_COURSE,
  REORDER_EXERCISE,
  PUBLISH_ALL_COURSES,
  PUBLISH_ALL_COURSES_SUCCESS,
  PUBLISH_ALL_COURSES_FAILURE,
  PUBLISH_ALL_EXERCISES,
  PUBLISH_ALL_EXERCISES_SUCCESS,
  PUBLISH_ALL_EXERCISES_FAILURE,
  GET_COURSES,
  GET_COURSES_SUCCESS,
  GET_COURSES_FAILURE,
  DELETE_EXERCISE,
  DELETE_EXERCISE_SUCCESS,
  DELETE_EXERCISE_FAILURE,
  UPDATE_EXERCISE,
  UPDATE_EXERCISE_SUCCESS,
  UPDATE_EXERCISE_FAILURE,
  UPDATE_QUESTION,
  UPDATE_ANSWER,
  UPDATE_QUESTION_SUCCESS,
  UPDATE_ANSWER_SUCCESS,
  GET_BENEFICIARY_EXERCISES,
  GET_BENEFICIARY_EXERCISES_SUCCESS,
  GET_BENEFICIARY_EXERCISES_FAILURE,
  GET_BENEFICIARY_PRACTICE_SESSION,
  GET_BENEFICIARY_PRACTICE_SESSION_SUCCESS,
  GET_BENEFICIARY_PRACTICE_SESSION_FAILURE,
  GET_BENEFICIARY_EXAM_SESSION,
  GET_BENEFICIARY_EXAM_SESSION_SUCCESS,
  GET_BENEFICIARY_EXAM_SESSION_FAILURE,
  UPDATE_EXERCISE_BENEFICIARY,
  UPDATE_EXERCISE_BENEFICIARY_SUCCESS,
  UPDATE_EXERCISE_BENEFICIARY_FAILURE,
  ISession,
  IExerciseBeneficiary,
  ELearningActionsType,
  ITheme,
  ELearningState,
  ICourse,
  IExercise,
  IAnswer,
  ISessionScore,
  RESET_SESSION,
  GET_BENEFICIARY_PRACTICE_SESSION_SCORE,
  GET_BENEFICIARY_PRACTICE_SESSION_SCORE_SUCCESS,
  GET_BENEFICIARY_PRACTICE_SESSION_SCORE_FAILURE,
  GET_BENEFICIARY_EXAM_SESSION_SCORE,
  GET_BENEFICIARY_EXAM_SESSION_SCORE_SUCCESS,
  GET_BENEFICIARY_EXAM_SESSION_SCORE_FAILURE,
  PUBLISH_COURSE,
  PUBLISH_COURSE_SUCCESS,
  PUBLISH_COURSE_FAILURE,
  CREATE_EXERCISE,
  CREATE_EXERCISE_SUCCESS,
} from "./e-learning.types";
import {
  reorder,
  updateBlocsOrderAfterDelete,
  updatePublishingStatusBlocs,
  updateStateAnswer,
  updateStateExcercisesBeneficiary,
  getExercisesFromSession,
} from "./e-learning.utils";
import { DictionnaryEntry } from "../../presentation/dictionnary/dictionnaryEntry";
import { updateBy } from "../../utils/update";

const INITIAL_STATE: ELearningState = {
  themes: [],
  theme: {
    id: "",
    alt: "",
    title: "",
  },
  courses: [],
  session: {
    id: "",
    themeId: "",
    userId: "",
    isFinished: false,
    counter: 0,
    exercisesBeneficiary: [],
  },
  sessionScore: {
    score: 0,
    length: 0,
    errors: 0,
    improvement: 0,
  },
  isAddMode: true,
  errors: undefined,
  loading: false,
  scoreLoading: false,
  dictionnary: [],
};

const eLearningReducer = (
  state = INITIAL_STATE,
  action: ELearningActionsType,
):
  | ELearningState
  | {
      themes: ITheme[] | undefined;
      theme: ITheme | undefined;
      courses: ICourse[] | undefined;
      isAddMode: boolean;
      loading: boolean;
      error: boolean;
    } => {
  switch (action.type) {
    case GET_ADMIN_THEMES:
    case GET_DICTIONNARY_ENTRIES:
    case GET_BENEFICIARY_THEMES:
    case GET_THEME_AND_COURSES:
    case GET_THEME_AND_EXERCISES:
    case PUBLISH_ALL_COURSES:
    case PUBLISH_ALL_EXERCISES:
    case GET_COURSES:
    case UPDATE_ANSWER:
    case DELETE_EXERCISE:
    case GET_BENEFICIARY_EXERCISES:
    case GET_BENEFICIARY_PRACTICE_SESSION:
    case GET_BENEFICIARY_EXAM_SESSION:
    case UPDATE_EXERCISE_BENEFICIARY:
    case UPDATE_QUESTION:
    case GET_BENEFICIARY_PRACTICE_SESSION_SCORE:
    case GET_BENEFICIARY_EXAM_SESSION_SCORE: {
      return {
        ...state,
        scoreLoading: true,
      };
    }

    case CREATE_COURSE:
      if (action.course) {
        return {
          ...state,
          loading: true,
          courses: [...state.courses, action.course],
        };
      }
      return { ...state, loading: true };

    case CREATE_COURSE_SUCCESS:
      return {
        ...state,
        loading: false,
        courses: [...state.courses, action.course as ICourse],
      };

    case UPDATE_COURSE:
      if (action.id) {
        return {
          ...state,
          loading: true,
          courses: state.courses.map((course) =>
            course.id === action.course?.id ? action.course : course,
          ),
        };
      }
      return { ...state, loading: true };

    case UPDATE_COURSE_SUCCESS:
      return {
        ...state,
        loading: false,
        courses: state.courses.map((course) =>
          course.id === action.course?.id ? action.course : course,
        ),
      };

    case CREATE_EXERCISE:
      if (action.exercise) {
        const themeToUpdate = state.themes.find(
          (theme) => theme.id === action.exercise?.themeId,
        );
        if (themeToUpdate) {
          const updatedTheme: ITheme = {
            ...themeToUpdate,
            exercises: [...(themeToUpdate.exercises ?? []), action.exercise],
          };
          return {
            ...state,
            loading: true,
            themes: state.themes.map((theme) =>
              theme.id === updatedTheme.id ? updatedTheme : theme,
            ),
          };
        }
      }
      return { ...state, loading: true };

    case CREATE_EXERCISE_SUCCESS:
      if (action.exercise) {
        const themeToUpdate = state.themes.find((theme) =>
          theme.exercises?.find(
            (exercise) => exercise.id === action.exercise?.id,
          ),
        );
        if (themeToUpdate) {
          const updatedTheme: ITheme = {
            ...themeToUpdate,
            exercises: themeToUpdate?.exercises?.map((exercise) =>
              exercise.id === action.id ? action.exercise! : exercise,
            ),
          };
          return {
            ...state,
            loading: true,
            themes: state.themes.map((theme) =>
              theme.id === updatedTheme.id ? updatedTheme : theme,
            ),
          };
        }
      }
      return { ...state, loading: true };

    case UPDATE_EXERCISE:
      if (action.id) {
        return {
          ...state,
          loading: true,
          theme: {
            ...state.theme,
            exercises: (state.theme.exercises || []).map((exercise) =>
              exercise.id === action?.exercise?.id ? action.exercise : exercise,
            ),
          },
        };
      }
      return { ...state, loading: true };

    case UPDATE_EXERCISE_SUCCESS:
      return {
        ...state,
        loading: false,
        theme: {
          ...state.theme,
          exercises: (state.theme.exercises || []).map((exercise) =>
            exercise.id === action?.exercise?.id ? action.exercise : exercise,
          ),
        },
      };

    case CREATE_THEME:
      if (action.theme) {
        return {
          ...state,
          loading: true,
          themes: [...state.themes, action.theme],
        };
      }
      return { ...state, loading: true };
    case CREATE_THEME_SUCCESS:
      if (action.theme) {
        return {
          ...state,
          loading: false,
          themes: [...state.themes, action.theme],
        };
      }
      return { ...state, loading: false };
    case GET_ADMIN_THEMES_SUCCESS:
    case GET_BENEFICIARY_THEMES_SUCCESS:
      return {
        ...state,
        themes: action.themes as ITheme[],
        courses: [],
        loading: false,
      };
    case GET_DICTIONNARY_ENTRIES_SUCCESS:
      return {
        ...state,
        dictionnary: action.dictionnaryEntries as DictionnaryEntry[],
        loading: false,
      };
    case GET_THEME_AND_COURSES_SUCCESS:
      return {
        ...state,
        theme: action.theme as ITheme,
        loading: false,
      };
    case GET_THEME_AND_EXERCISES_SUCCESS:
      return {
        ...state,
        theme: action.theme as ITheme,
        loading: false,
      };
    case RESET_THEME:
      return {
        ...state,
        theme: {
          id: "",
          alt: "",
          title: "",
        },
      };
    case DELETE_THEME:
      if (action.theme?.id) {
        return {
          ...state,
          loading: true,
          themes: state.themes.filter((theme) => theme.id !== action.theme?.id),
        };
      }
      return { ...state, loading: true };
    case DELETE_THEME_SUCCESS:
      return {
        ...state,
        themes: state.themes.filter((theme) => theme.id !== action.theme?.id),
        loading: false,
        error: false,
      };

    case DELETE_COURSE:
      if (action.id) {
        return {
          ...state,
          loading: true,
          courses: state.courses.filter((course) => course.id !== action.id),
        };
      }
      return { ...state, loading: true };
    case DELETE_COURSE_SUCCESS:
      return {
        ...state,
        courses: state.courses.filter((course) => course.id !== action.id),
        loading: false,
        error: false,
      };

    case PUBLISH_COURSE:
      if (action.id) {
        return {
          ...state,
          loading: true,
          courses: state.courses.map((course) =>
            course.id === action.id ? action.course! : course,
          ),
        };
      }
      return { ...state, loading: true };
    case PUBLISH_COURSE_SUCCESS:
      return {
        ...state,
        courses: state.courses.filter((course) => course.id !== action.id),
        loading: false,
        error: false,
      };
    case UPDATE_THEME:
      if (action.id) {
        return {
          ...state,
          loading: true,
          themes: state.themes.map((theme) =>
            theme.id === action.id ? action.theme! : theme,
          ),
        };
      }
      return { ...state, loading: true };
    case UPDATE_THEME_SUCCESS:
      return {
        ...state,
        loading: false,
        themes: state.themes.map((theme) =>
          theme.id === action.theme?.id ? action.theme : theme,
        ),
        theme: action?.theme
          ? { ...state.theme, ...action.theme }
          : state.theme,
      };
    case SET_ADD_MODE:
      return {
        ...state,
        isAddMode: action.value as boolean,
      };

    case DELETE_DICTIONNARY_ENTRY:
      return {
        ...state,
        loading: true,
        dictionnary: state.dictionnary?.filter(
          (dictionnaryEntry) =>
            dictionnaryEntry.id !== action.dictionnaryEntryId,
        ),
      };

    case ADD_DICTIONNARY_ENTRY:
      if (action.dictionnaryEntry) {
        const { id, word, definition } = action.dictionnaryEntry;
        const newDictionnaryEntry = { id, word, definition };
        return {
          ...state,
          loading: true,
          dictionnary: [...(state.dictionnary || []), newDictionnaryEntry].sort(
            (a, b) => a.word.localeCompare(b.word),
          ),
        };
      }
      return state;

    case UPDATE_DICTIONNARY_ENTRY:
      if (action.dictionnaryEntry) {
        const findIndex = (dictionnaryEntry) =>
          dictionnaryEntry.id === action.dictionnaryEntry?.id;
        return {
          ...state,
          dictionnary: updateBy(
            state.dictionnary,
            action.dictionnaryEntry,
            findIndex,
          ).sort((a, b) => a.word.localeCompare(b.word)),
        };
      }
      return state;
    case REORDER_COURSE:
      return {
        ...state,
        theme: {
          ...state.theme,
          courses: reorder(
            action.reorder?.items as ICourse[],
            action.reorder?.order as number,
            action.reorder?.isUp as boolean,
          ),
        },
      };
    case REORDER_EXERCISE:
      return {
        ...state,
        theme: {
          ...state.theme,
          exercises: reorder(
            action.reorder?.items as IExercise[],
            action.reorder?.order as number,
            action.reorder?.isUp as boolean,
          ),
        },
      };
    case PUBLISH_ALL_COURSES_SUCCESS:
      return {
        ...state,
        theme: {
          ...state.theme,
          courses: updatePublishingStatusBlocs(
            state.theme.courses,
            action.isPublished as boolean,
          ),
        },
      };
    case PUBLISH_ALL_EXERCISES_SUCCESS:
      return {
        ...state,
        theme: {
          ...state.theme,
          exercises: updatePublishingStatusBlocs(
            state.theme.exercises,
            action.isPublished as boolean,
          ),
        },
      };
    case GET_COURSES_SUCCESS:
      return {
        ...state,
        courses: action.courses as ICourse[],
      };

    case UPDATE_QUESTION_SUCCESS:
      return {
        ...state,
        loading: true,
        theme: {
          ...state.theme,
          exercises: (state.theme.exercises || []).map((exercise) =>
            exercise.id === action?.question?.exerciseId
              ? { ...exercise, question: action?.question }
              : exercise,
          ),
        },
      };

    case UPDATE_ANSWER_SUCCESS:
      return {
        ...state,
        loading: true,
        theme: {
          ...state.theme,
          exercises: updateStateAnswer(
            state.theme.exercises,
            action?.answer as IAnswer,
          ),
        },
      };

    case DELETE_EXERCISE_SUCCESS:
      return {
        ...state,
        theme: {
          ...state.theme,
          exercises: updateBlocsOrderAfterDelete(
            state.theme.exercises as IExercise[],
            action.id as string,
          ),
        },
      };
    case GET_BENEFICIARY_EXERCISES_SUCCESS:
      return {
        ...state,
        theme: {
          ...state.theme,
          exercises: action.exercises as IExercise[],
        },
        loading: false,
      };
    case GET_BENEFICIARY_PRACTICE_SESSION_SUCCESS:
      return {
        ...state,
        session: action.session as ISession,
        loading: false,
      };
    case GET_BENEFICIARY_EXAM_SESSION_SUCCESS:
      return {
        ...state,
        theme: {
          ...state.theme,
          exercises: getExercisesFromSession(
            action.session as ISession,
            state.theme.exercises as IExercise[],
          ),
        },
        session: action.session as ISession,
        loading: false,
      };
    case RESET_SESSION:
      return {
        ...state,
        session: {
          id: "",
          score: "",
          themeId: "",
          userId: "",
          isFinished: false,
          counter: 0,
          exercisesBeneficiary: [],
          exercises: [],
        },
      };
    case UPDATE_EXERCISE_BENEFICIARY_SUCCESS:
      return {
        ...state,
        session: {
          ...state.session,
          exercisesBeneficiary: updateStateExcercisesBeneficiary(
            state.session.exercisesBeneficiary as IExerciseBeneficiary[],
            action.exerciseBeneficiary as IExerciseBeneficiary,
          ),
        },
        loading: false,
      };

    case GET_BENEFICIARY_PRACTICE_SESSION_SCORE_SUCCESS:
    case GET_BENEFICIARY_EXAM_SESSION_SCORE_SUCCESS:
      return {
        ...state,
        scoreLoading: false,
        sessionScore: action?.sessionScore as ISessionScore,
      };

    case CREATE_THEME_FAILURE:
    case CREATE_COURSE_FAILURE:
    case GET_ADMIN_THEMES_FAILURE:
    case GET_DICTIONNARY_ENTRIES_FAILURE:
    case GET_BENEFICIARY_THEMES_FAILURE:
    case GET_THEME_AND_COURSES_FAILURE:
    case GET_THEME_AND_EXERCISES_FAILURE:
    case DELETE_THEME_FAILURE:
    case UPDATE_THEME_FAILURE:
    case DELETE_COURSE_FAILURE:
    case UPDATE_COURSE_FAILURE:
    case PUBLISH_COURSE_FAILURE:
    case ADD_DICTIONNARY_ENTRY_FAILURE:
    case DELETE_DICTIONNARY_ENTRY_FAILURE:
    case UPDATE_DICTIONNARY_ENTRY_FAILURE:
    case PUBLISH_ALL_COURSES_FAILURE:
    case PUBLISH_ALL_EXERCISES_FAILURE:
    case GET_COURSES_FAILURE:
    case UPDATE_EXERCISE_FAILURE:
    case DELETE_EXERCISE_FAILURE:
    case GET_BENEFICIARY_EXERCISES_FAILURE:
    case GET_BENEFICIARY_PRACTICE_SESSION_FAILURE:
    case GET_BENEFICIARY_EXAM_SESSION_FAILURE:
    case UPDATE_EXERCISE_BENEFICIARY_FAILURE:
    case GET_BENEFICIARY_PRACTICE_SESSION_SCORE_FAILURE:
    case GET_BENEFICIARY_EXAM_SESSION_SCORE_FAILURE:
      return {
        ...state,
        loading: false,
        scoreLoading: false,
        errors: action.message,
      };
    default:
      return state;
  }
};

export default eLearningReducer;

import fileUpload from "../../../src/assets/icons/file-upload-multiple.svg";
import { useFileDragAndDrop } from "../DragAndDrop/DragAndDropContext";
import dragIcon from "../../../src/assets/icons/cross-drag.svg";
import { ChangeEvent, FC, useCallback, useState } from "react";
import SideBarToggle from "../SideBarToggle/SideBarToggle";
import IconButton from "../IconButton/IconButton";
import { IoTrashSharp } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import "./FileUpload.scss";

interface FileUploadProps {
  label: string;
  accept: string;
  multiple?: boolean;
}

const appendFiles = (newFiles: File[]) => (prevFiles: File[]) => {
  const files = newFiles.reduce((acc, newFile) => {
    const exist = Boolean(
      acc.find((prevFile) => prevFile.name === newFile.name),
    );

    if (exist) return acc;

    return [...acc, newFile];
  }, prevFiles);

  return files;
};

const FileUpload: FC<FileUploadProps> = ({ label, accept, multiple }) => {
  const { t } = useTranslation();

  const [files, setFiles] = useState<File[]>([]);

  const { onFileDragStart } = useFileDragAndDrop();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newFiles: File[] = event.target.files
      ? Array.from(event.target.files)
      : [];

    setFiles(appendFiles(newFiles));
  };

  const handleDelete = useCallback(
    (name: string) => () => {
      setFiles((prevFiles) =>
        prevFiles.filter((prevFile) => prevFile.name !== name),
      );
    },
    [],
  );

  return (
    <SideBarToggle title={t("theme_import-file-title")}>
      <div className="file-upload-input">
        <img src={fileUpload} alt="upload icon" />
        <p className="file-upload-input-label">{t(label)}</p>
        <input
          multiple={multiple}
          type="file"
          onChange={handleChange}
          accept={accept}
        />
      </div>
      <div className="file-upload-field-list">
        {files.length > 0 && (
          <p className="file-upload-list-title">
            {t("theme_imported-files-title")}
          </p>
        )}
        {files.map((file) => {
          return (
            <div
              key={`${file.name}-${file.lastModified}`}
              className="file-upload-file-field"
              draggable="true"
              onDragStart={onFileDragStart(file)}
            >
              <div className="file-upload-file-infos">
                <img src={dragIcon} alt="drag icon" />
                <p className="file-upload-file-title">{file.name}</p>
              </div>
              <IconButton color="dangerous" onClick={handleDelete(file.name)}>
                <IoTrashSharp />
              </IconButton>
            </div>
          );
        })}
      </div>
    </SideBarToggle>
  );
};

export default FileUpload;
